import { useRouter } from 'next/router'
import { parseRegionalUrl } from '@utils/regional'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { ProductCardType } from '@utils/searchUtils'
import { ExcludedProductTypes } from '@src/aem-content/aem-types'

// These routes are refered to as different names than their actual text. So an enum is used to always return the colloquial name.
export enum ShorthandRouteNames {
  B2bAuth = 'b2b authentication',
  Docs = 'document library',
  DocumentsSearch = 'document search',
  PreRegisteredEmail = 'pre-registered email',
  Product = 'pdp',
  SpecificationSheet = 'spec sheet',
  SemiConfigurators = 'semi-configurators',
  WimsAuth = 'WIMS auth',
  X_Cart = 'x cart',
  Home = 'homepage',
}

// A select few of our pages directories contain subdirectories with entirely different site features/functionalities within them. We need to detect and handle name returning specially for these.
const isDeepNamedRoute = (routePath): boolean => {
  const deepNamedRoutes = [
    'order-center',
    'configurators',
    'semi-configurators',
  ]

  return deepNamedRoutes.some((deepRoute) => routePath.includes(deepRoute))
}

const makePascalIndex = (pathPart: string): string => {
  return pathPart
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join('')
}

const analyticsFriendlyPageName = (pathPart: string): string => {
  return pathPart.toLowerCase().replaceAll('-', ' ').trim()
}

export const useCategorizePage = (): string => {
  const routerData = useRouter()
  const parsedRegionalRoute = parseRegionalUrl(routerData.asPath)

  // Handle unknown or undefined route data with an uncategorized path message and router asPath info if available. Homepage is just / and should return as such.
  if (!parsedRegionalRoute.pathAfterBasename) {
    return `uncategorized path - ${routerData?.asPath}`
  }

  if (parsedRegionalRoute?.pathAfterBasename === '/') {
    return ShorthandRouteNames.Home
  }

  const pathParts = parsedRegionalRoute?.pathAfterBasename?.split('/')

  /*
    Check if the current route is one of our deeply nested routes where many varied functionalities branch of off one master route.
    If the path is one of these "deep" routes we want to grab that as the named route we hand back for analytics page categorization instead of the first stub.

    And also if they are on the index of a "deep" route we'll simply use that deep route as the name given back.
  */
  const isDeepRoute = isDeepNamedRoute(parsedRegionalRoute.pathAfterBasename)

  const pathName =
    isDeepRoute && pathParts.length > 2 ? pathParts[2] : pathParts[1]

  const pathPascalKey = makePascalIndex(pathName)
  return pathPascalKey in ShorthandRouteNames
    ? ShorthandRouteNames[pathPascalKey]
    : analyticsFriendlyPageName(pathName)
}

export const ProductCardTypeToPageMap: Record<
  Exclude<ProductCardType, ExcludedProductTypes>,
  AddToCartPagesEnum
> = {
  [ProductCardType.BuyItAgain]: AddToCartPagesEnum.AccountDashboard,
  [ProductCardType.Recently]: AddToCartPagesEnum.RecentlyViewedProducts,
  [ProductCardType.Recommended]: AddToCartPagesEnum.RecommendedProducts,
  [ProductCardType.Featured]: AddToCartPagesEnum.FeaturedProducts,
  [ProductCardType.Related]: AddToCartPagesEnum.RelatedProducts,
  [ProductCardType.CustomersAlsoViewed]: AddToCartPagesEnum.CustomersAlsoViewed,
  [ProductCardType.FrequentlyPurchased]:
    AddToCartPagesEnum.FrequentlyPurchasedProducts,
  [ProductCardType.CartRecommendedProducts]:
    AddToCartPagesEnum.CartRecommendedProducts,
  [ProductCardType.CompareSimilarItems]: AddToCartPagesEnum.CompareSimilarItems,
  [ProductCardType.BestSellers]: AddToCartPagesEnum.BestSellerProducts,
  [ProductCardType.ProductHeroCard]: AddToCartPagesEnum.ProductHeroCard,
  [ProductCardType.BuyAgainHomepage]: AddToCartPagesEnum.BuyAgain,
  [ProductCardType.NewArrivals]: AddToCartPagesEnum.NewArrivals,
  [ProductCardType.PopularProducts]: AddToCartPagesEnum.PopularProducts,
}
