import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useCategorizePage } from '@utils/analytics/CategorizePage'
import { parseRegionalUrl } from '@src/utils/regional'
import useScript from '../../utils/useScript'
import { useCookies } from '@src/utils/cookies'
import Fade from '@material-ui/core/Fade'
import Head from 'next/head'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import {
  sendChatOpenEvent,
  sendCommonDetailClickEvent,
} from '@src/utils/analytics'
import { useGetChatConfigQuery } from '@src/queries/GetChatConfig.generated'
import { ChatProps } from './'

export enum ChatChannel {
  TechService = 'TS',
  CustomerService = 'CC',
}

type initESWConfig = {
  embeddedSVC: any
  targetElement: HTMLDivElement
  chatConfig: any | undefined | null
}

const styles =
  '#chat-container .embeddedServiceHelpButton .helpButton .uiButton {background-color: #0f69af;font-family: inherit}'

function initESW({ embeddedSVC, targetElement, chatConfig }: initESWConfig) {
  /**
   * Salesforce Embedded Chat Service Docs are spotty at best, godspeed:
   * https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_customize_chat_parameters.htm
   *
   * Sometimes referring to actual source code will lead to insightful docstrigs
   * https://service.force.com/embeddedservice/5.0/esw.js
   */
  embeddedSVC.settings.targetElement = targetElement
  embeddedSVC.settings.displayHelpButton =
    chatConfig.displayHelpButton.toLowerCase() === 'true'
  embeddedSVC.settings.language = chatConfig.language
  embeddedSVC.settings.defaultMinimizedText = chatConfig.defaultMinimizedText
  embeddedSVC.settings.enabledFeatures = [chatConfig.enabledFeatures]
  embeddedSVC.settings.entryFeature = chatConfig.entryFeature
  embeddedSVC.addEventHandler('onChatRequestSuccess', function () {
    // analtyics event for Stat Chatting button
    sendCommonDetailClickEvent({
      event: 'live_chat_interaction',
      action: 'start chat - name email subject',
      section: 'chat',
      component: 'modal',
      elementType: 'button',
      elementText: 'start chatting',
      coreEvent: 'no',
    })
  })

  embeddedSVC.init(
    chatConfig.baseCoreURL,
    chatConfig.communityEndpointURL,
    'https://service.force.com',
    chatConfig.orgId,
    chatConfig.eswLiveAgentDevName,
    {
      baseLiveAgentContentURL: chatConfig.baseLiveAgentContentURL,
      baseLiveAgentURL: chatConfig.baseLiveAgentURL,
      isOfflineSupportEnabled: false,
      deploymentId: chatConfig.deploymentId,
      buttonId: chatConfig.buttonId,
      eswLiveAgentDevName: chatConfig.eswLiveAgentDevName,
    }
  )
}

/**
 * This component integrates Salesforce's Live Agent/Embedded Chat Service in to
 * our application.
 * There is no accompanying `destroy` method for the Salesforce's ESW script
 * `init` functions and multiple `init`s fail silently.
 *
 * Thus we manually clean up the ESW scripts on unmount.
 */
export const Chat: React.FC<ChatProps> = (props) => {
  const scriptStatus = useScript(
    'https://service.force.com/embeddedservice/5.0/esw.min.js'
  )

  const router = useRouter()
  const { currentUser } = useCurrentUser()
  const pageCategory = useCategorizePage()
  const chatEl = useRef<HTMLDivElement>(null)
  const [cookies] = useCookies(['language', '_ga'])

  const isClient = typeof window === 'object'
  const isESWReady =
    isClient && scriptStatus === 'ready' && !!window.embedded_svc

  const [initialized, setInitialized] = useState<boolean>(false)

  /**
   * initialize the embedded service chat once it is available.
   */
  const { data } = useGetChatConfigQuery({
    ssr: false,
    skip: !currentUser?.metadata.isChatEnabled,
  })

  //Find the array item with the matching chat channel since API gives us just an array of all chats.
  const chatConfig = data?.getChatConfig?.find(
    (item) => props.channel === item?.chatType
  )

  useEffect(() => {
    if (!initialized && isESWReady && chatEl.current && chatConfig) {
      initESW({
        embeddedSVC: window.embedded_svc,
        targetElement: chatEl.current,
        chatConfig: chatConfig,
      })
      setInitialized(true)
    }
    return () => {
      if (initialized) {
        /**
         * When the chat component is unmounted we clear all of the original salesforce scripts
         * This allows them to be reinitialized on any subsequent pages
         */
        document.querySelectorAll('script').forEach((i) => {
          i.src.includes('force') && i.remove()
        })

        // delete is not supported by ie11, instead we null the field rather than brining in polyfills
        // delete window.embedded_svc
        window.embedded_svc = null
      }
    }
  }, [
    isESWReady,
    chatEl,
    setInitialized,
    cookies,
    props.text,
    props.channel,
    initialized,
    chatConfig,
  ])

  if (!currentUser?.metadata.isChatEnabled) return null

  //Don't render the chat widget if there isn't a route match after country/lang is stripped.
  const parsedUrl = parseRegionalUrl(router.asPath)
  if (
    !chatConfig?.routes.some((route) =>
      parsedUrl?.pathAfterBasename?.includes(route)
    ) &&
    !props.isAemPageChat
  ) {
    return null
  }

  const onClick = () => {
    const agentOnline = !chatEl.current?.innerText.includes('Agent Offline')
    // GA4-239 - onClick was firing for every click inside the chat window
    // adding extra check of display style attribute to fix issue
    const embeddedChatButtonEnabled =
      chatEl.current?.children[1]?.attributes?.getNamedItem('style')?.value

    //Currently the only way this chat (at least in the context of this component) can be accessed is via the bubble in the bottom left. So the value is hardcoded based on SELF-612s value.
    if (
      agentOnline &&
      (!embeddedChatButtonEnabled ||
        embeddedChatButtonEnabled?.includes('block'))
    ) {
      sendCommonDetailClickEvent({
        event: 'live_chat_interaction',
        action: 'initiate chat window',
        section: 'chat',
        component: 'right rail',
        elementType: 'button',
        elementText: props.text,
        coreEvent: 'no',
      })
      sendChatOpenEvent('initiate chat - bubble', pageCategory)
    }
  }
  return (
    <>
      <Head>
        <style type="text/css">{styles}</style>
      </Head>
      <Fade in={initialized} timeout={{ enter: 5000 }}>
        <div
          ref={chatEl}
          id="chat-container"
          role="button"
          onClick={onClick}
          onKeyDown={onClick}
          tabIndex={0}
        />
      </Fade>
    </>
  )
}
