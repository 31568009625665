// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetChatConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetChatConfigQuery = { __typename?: 'Query', getChatConfig?: Array<{ __typename?: 'ChatConfigResponse', countryCode?: string | null, storeId?: number | null, environments?: string | null, baseCoreURL?: string | null, communityEndpointURL?: string | null, baseLiveAgentURL?: string | null, orgId?: string | null, deploymentId?: string | null, buttonId?: string | null, eswLiveAgentDevName?: string | null, baseLiveAgentContentURL?: string | null, displayHelpButton?: string | null, language?: string | null, defaultMinimizedText?: string | null, disabledMinimizedText?: string | null, loadingText?: string | null, offlineSupportMinimizedText?: string | null, enabledFeatures?: string | null, entryFeature?: string | null, chatType?: string | null, routes: Array<string> } | null> | null };


export const GetChatConfigDocument = gql`
    query GetChatConfig {
  getChatConfig {
    countryCode
    storeId
    environments
    baseCoreURL
    communityEndpointURL
    baseLiveAgentURL
    orgId
    deploymentId
    buttonId
    eswLiveAgentDevName
    baseLiveAgentContentURL
    displayHelpButton
    language
    defaultMinimizedText
    disabledMinimizedText
    loadingText
    offlineSupportMinimizedText
    enabledFeatures
    entryFeature
    chatType
    routes
  }
}
    `;
export type GetChatConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetChatConfigQuery, GetChatConfigQueryVariables>, 'query'>;

    export const GetChatConfigComponent = (props: GetChatConfigComponentProps) => (
      <ApolloReactComponents.Query<GetChatConfigQuery, GetChatConfigQueryVariables> query={GetChatConfigDocument} {...props} />
    );
    

/**
 * __useGetChatConfigQuery__
 *
 * To run a query within a React component, call `useGetChatConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChatConfigQuery, GetChatConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetChatConfigQuery, GetChatConfigQueryVariables>(GetChatConfigDocument, options);
      }
export function useGetChatConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChatConfigQuery, GetChatConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetChatConfigQuery, GetChatConfigQueryVariables>(GetChatConfigDocument, options);
        }
export type GetChatConfigQueryHookResult = ReturnType<typeof useGetChatConfigQuery>;
export type GetChatConfigLazyQueryHookResult = ReturnType<typeof useGetChatConfigLazyQuery>;
export type GetChatConfigQueryResult = ApolloReactCommon.QueryResult<GetChatConfigQuery, GetChatConfigQueryVariables>;