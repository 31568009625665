import getConfig from 'next/config'
import { Chat as LegacyChat, ChatChannel } from './Chat'
import { EinsteinChat } from './EinsteinChat'

export interface ChatProps {
  text?: string
  channel?: ChatChannel
  productNumber?: string
  productName?: string
  isAemPageChat?: boolean
}

const {
  publicRuntimeConfig: { einsteinChat },
} = getConfig()

const Chat = einsteinChat?.baseCoreURL ? EinsteinChat : LegacyChat

export { Chat, ChatChannel }
